// Utils for app
export const animateCSS = (element, animation, prefix = 'animate__') =>
  // We create a Promise and return it
  new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`;
    const node = document.querySelector(element);

    node.classList.add(`${prefix}animated`, animationName);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd() {
      node.classList.remove(`${prefix}animated`, animationName);
      node.removeEventListener('animationend', handleAnimationEnd);

      resolve('Animation ended');
    }

    node.addEventListener('animationend', handleAnimationEnd);
  });
// For list add forms filed
export function baseEditInput(defaultValue = '') {
  return [
    {
      type: 'text',
      placeholder: '',
      value: defaultValue,
      tooltip: true,
      editType: 'label',
    },
    {
      type: 'text',
      placeholder: '',
      tooltip: true,
      editType: 'placehoder',
    },
  ];
}

export function getSiteIDFromUrl() {
  const search = window.location.href.split('?')[1]
  return new URLSearchParams(String(search)).get('siteID') || 1
}

export function getDefaultThankYouTitle() {
  const mapTitleBySiteID = {
    1: 'CÁM ƠN BẠN'
  }
  const siteID = getSiteIDFromUrl()
  return mapTitleBySiteID[siteID] || 'THANK YOU'
}

export function getDefaultThankYouSubtitle() {
  const mapSubTitleBySiteID = {
    1: 'Chúng tôi chân thành cám ơn bạn đã bớt chút thời gian để điền thông tin!'
  }
  const siteID = getSiteIDFromUrl()
  return mapSubTitleBySiteID[siteID] || 'We appreciate your time!'
}

export const IS_DEV = process.env.NODE_ENV === 'development';
